<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <b-row class="no-gutters mb-3">
          <b-col>
            <div class="font-weight-bold ft-20 header-tablepage">
              Serial Custom Object
            </div>
          </b-col>
        </b-row>
        <div class="no-gutters bg-white">
          <b-tabs v-model="tabIndex">
            <b-tab title="Setting">
              <div class="p-3">
                <b-row>
                  <b-col cols="12" md="6">
                    <InputText
                      textFloat="Name"
                      placeholder="Name"
                      type="text"
                      name="name"
                      isRequired
                      :v="$v.form.name"
                      :isValidate="$v.form.name.$error"
                      v-model="form.name"
                  /></b-col>
                  <b-col cols="12" md="6">
                    <InputText
                      textFloat="Sort Order"
                      placeholder="Sort Order"
                      type="number"
                      name="sort_order"
                      v-model="form.sort_order"
                      :v="$v.form.sort_order"
                      :isValidate="$v.form.sort_order.$error"
                  /></b-col>
                </b-row>
              </div>
              <div class="no-gutters bg-white mt-3 py-2 px-3">
                <b-form-checkbox
                  switch
                  class="radio-active"
                  size="lg"
                  :value="1"
                  :unchecked-value="0"
                  v-model="form.is_active"
                >
                  <span class="ml-2 main-label">Active</span>
                </b-form-checkbox>
              </div>
            </b-tab>
            <b-tab title="Fields Setting">
              <CustomField :fields="fields" ref="fields" />
            </b-tab>

            <b-tab title="Data" v-if="$route.params.id != 0">
              <ResponseReport />
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <FooterAction
        @submit="submit"
        routePath="/setting/product-setting/serial-custom-object"
      />
    </div>
  </div>
</template>

<script>
import CustomField from "./components/CustomField.vue";

import ResponseReport from "./components/ResponseReport.vue";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, integer, minValue } from "vuelidate/lib/validators";
export default {
  components: {
    CustomField,
    OtherLoading,
    // DisplayPreview,
    // Automation,
    ResponseReport,
  },
  data() {
    return {
      tabIndex: 0,
      isLoading: false,
      id: this.$route.params.id,
      fields: [
        {
          id: 0,
          serial_custom_object_id: 0,
          field_type_id: 1,
          required: false,
          temp_index: 1,
          img: "",
          validate_text: "",
          name: "TEXT",
          is_display: 1,
          is_required: 0,
          delete: false,
          field_profile_type_id: 0,
          select_branch: [],
          field_choices: [],
          field_conditions: [
            {
              serial_custom_object_field_id: 0,
              field_condition_type_id: 1,
              value: "text",
            },
            {
              serial_custom_object_field_id: 0,
              field_condition_type_id: 2,
              value: "",
            },
            {
              serial_custom_object_field_id: 0,
              field_condition_type_id: 3,
              value: "",
            },
            {
              serial_custom_object_field_id: 0,
              field_condition_type_id: 4,
              value: "",
            },
          ],
        },
      ],
      form: {
        id: 0,
        name: "",
        is_active: 1,
        sort_order: 0,
      },
      rawData: {
        form: {
          id: 0,
          name: "",

          is_active: 1,

          sort_order: 0,
        },
        fields: [],
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        image_banner: {
          required,
        },
        button_label: {
          required,
        },
        sort_order: {
          integer,
          minValue: minValue(0),
        },
      },
      fields: {
        $each: {
          name: {
            required,
          },
        },
      },
    };
  },

  async created() {
    if (this.id != 0) this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const resp = await this.axios(
        `SerialProduct/serial_custom_object/${this.id}`
      );
      var index = 1;

      for (const fields of resp.data.detail.fields) {
        fields.temp_index = index;
        index++;
      }

      this.fields = resp.data.detail.fields;
      this.form = resp.data.detail.custom_object;

      this.rawData = resp.data.detail;
      this.isLoading = false;
    },
    async submit() {
      this.$refs.fields.$v.additionnalQuestion.$touch();
      this.$v.form.$touch();
      if (this.$v.form.name.$error) {
        return (this.tabIndex = 0);
      }

      if (this.$refs.fields.$v.additionnalQuestion.$error) {
        return (this.tabIndex = 1);
      }

      this.$bus.$emit("showLoading");
      // this.isLoading = true;

      this.form.sort_order = !this.form.sort_order ? 0 : this.form.sort_order;
      this.form.user_limit = !this.form.user_limit ? 0 : this.form.user_limit;
      this.rawData.fields = this.fields.map((el, index) => {
        return { ...el, sort_order: index };
      });
      this.rawData.form = this.form;
      let payload = {
        fields: this.rawData.fields,
        custom_object: this.rawData.form,
      };
      const resp = await this.axios.post(
        `/SerialProduct/serial_custom_object`,
        payload
      );
      this.$bus.$emit("hideLoading");
      if (resp.data.result == 1) {
        this.successAlert().then(() =>
          this.$router.push("/setting/product-setting/serial-custom-object")
        );
      } else {
        this.errorAlert(resp.data.message);
      }
      // this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
