<template>
  <div>
    <div class="p-3 bg-white mb-5">
      <b-row>
        <b-col sm="4" class="">
          <div class="header-secondary title-tabs header-title py-3">
            <b>Question List</b>
            <b-button class="add-btn" @click="addQuestion">
              <b-icon icon="plus-square" class="mr-2"></b-icon
              ><span>Create New</span></b-button
            >
          </div>
        </b-col>
        <b-col sm="8" class="">
          <div
            class="header-secondary title-tabs header-title d-none d-sm-block py-3"
          >
            <b>Detail</b>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4" class="">
          <draggable :list="additionnalQuestion" @end="changeDrag">
            <div
              class="p-2 question-panel shadow-sm overflow-auto"
              v-for="(addQues, index) of additionnalQuestion"
              :key="'add-' + index"
              @click="handleClickActive(true, index)"
            >
              <div
                :class="[
                  'mt-2 p-3 question-card pointer',
                  index == activeIndex && 'active',
                ]"
              >
                <div class="d-flex align-items-center" style="flex-flow: wrap">
                  <span class="mr-2 text-black font-weight-bold"
                    >Question {{ index + 1 }} :</span
                  >
                  <div class="mr-2 type-box">
                    {{ questionTypeText(addQues.field_type_id) }}
                  </div>
                  <span style="word-break: break-all"></span>
                </div>
                <div class="mt-1">
                  <p class="mb-1 text-light-grey text-truncate">
                    {{ addQues.name }}
                  </p>
                </div>
              </div>
            </div>
          </draggable>
        </b-col>
        <b-col sm="8" class="">
          <div class="header-secondary title-tabs d-block d-sm-none">
            <span>Detail</span>
          </div>

          <div class="pt-2 bg-white text-normal shadow-sm">
            <div class="p-3">
              <div class="content-between">
                <div class="d-flex justify-content-end align-items-center">
                  <label class="mb-0 mr-2">Required</label>
                  <b-form-checkbox
                    class="mb-1 mr-2"
                    size="lg"
                    v-model="additionnalQuestion[activeIndex].is_required"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                  </b-form-checkbox>
                  <b-icon
                    v-if="activeIndex != 0"
                    size="lg"
                    icon="trash-fill"
                    class="icon-size pointer"
                    @click="deleteQuestion"
                  />
                </div>
              </div>
              <div class="mt-3 w-25">
                <b-form-select
                  v-model="additionnalQuestion[activeIndex].field_type_id"
                  :options="fieldTypes"
                  @change="changeQeustionType"
                  value-field="id"
                  text-field="name"
                ></b-form-select>
              </div>
              <div class="mt-3">
                <div
                  v-if="
                    !(
                      additionnalQuestion[activeIndex].field_type_id == 10 ||
                      additionnalQuestion[activeIndex].field_type_id == 11 ||
                      additionnalQuestion[activeIndex].field_type_id == 13 ||
                      additionnalQuestion[activeIndex].field_type_id == 14
                    )
                  "
                >
                  <InputText
                    class="text-float-normal w-50"
                    textFloat="Validate Text"
                    v-model="additionnalQuestion[activeIndex].validate_text"
                    :placeholder="
                      additionnalQuestion[activeIndex].validate_text
                    "
                    :disabled="
                      additionnalQuestion[activeIndex].field_type_id == 10 ||
                      additionnalQuestion[activeIndex].field_type_id == 11 ||
                      additionnalQuestion[activeIndex].field_type_id == 13 ||
                      additionnalQuestion[activeIndex].field_type_id == 14
                    "
                    type="text"
                  />
                </div>
                <div
                  v-if="
                    !(
                      additionnalQuestion[activeIndex].field_type_id == 9 ||
                      additionnalQuestion[activeIndex].field_type_id == 13 ||
                      additionnalQuestion[activeIndex].field_type_id == 14
                    )
                  "
                >
                  <InputText
                    isRequired
                    :class="[
                      'text-float-normal question-name-input margin-input-5',
                      isSubmit && !additionnalQuestion[activeIndex].name
                        ? 'error'
                        : '',
                    ]"
                    textFloat="Question"
                    v-model="additionnalQuestion[activeIndex].name"
                    :disabled="
                      additionnalQuestion[activeIndex].field_type_id == 9 ||
                      additionnalQuestion[activeIndex].field_type_id == 13 ||
                      additionnalQuestion[activeIndex].field_type_id == 14
                    "
                    :placeholder="additionnalQuestion[activeIndex].name"
                    type="text"
                    :v="$v.additionnalQuestion.$each.$iter[activeIndex].name"
                    :isValidate="
                      $v.additionnalQuestion.$each.$iter[activeIndex].name
                        .$error
                    "
                  />
                </div>
              </div>
              <div class="my-3">
                <FieldTextDetail
                  v-if="additionnalQuestion[activeIndex].field_type_id == 1"
                  :questionList="additionnalQuestion"
                  :questionActive="activeIndex"
                />

                <FieldChoiceDetail
                  v-if="
                    additionnalQuestion[activeIndex].field_type_id >= 2 &&
                    additionnalQuestion[activeIndex].field_type_id <= 4
                  "
                  :questionList="additionnalQuestion"
                  :questionActive="activeIndex"
                />
                <DateInput
                  v-if="additionnalQuestion[activeIndex].field_type_id == 5"
                />

                <ModalSelectBranch
                  v-if="
                    additionnalQuestion[activeIndex].branchList &&
                    additionnalQuestion[activeIndex].branchList.length > 0 &&
                    additionnalQuestion[activeIndex].field_type_id == 6
                  "
                  :list="additionnalQuestion[activeIndex].branchList"
                  @selectBranch="selectBranch"
                  :field="additionnalQuestion[activeIndex]"
                  text="Select Branch"
                  id=""
                  :BranchSelectList="
                    additionnalQuestion[activeIndex].select_branch
                  "
                />
                <Date
                  v-if="additionnalQuestion[activeIndex].field_type_id == 7"
                  class=""
                />

                <InputTextArea
                  v-if="additionnalQuestion[activeIndex].field_type_id == 12"
                  textFloat=""
                  type="text"
                  class="mt-3 custom-input"
                  placeholder=""
                  rows="3"
                />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Date from "@/components/form/preview/Date";
import DateInput from "@/components/form/preview/DateInput";
import ModalSelectBranch from "@/components/ModalSelectBranch";
import OtherLoading from "@/components/loading/OtherLoading";
import FieldChoiceDetail from "@/components/form/detail/FieldChoiceDetail";
import FieldTextDetail from "@/components/form/detail/FieldTextDetail";

import InputText from "@/components/inputs/InputText";
import InputTextArea from "@/components/inputs/InputTextArea";
import Multiselect from "vue-multiselect";
import { required, email, requiredIf } from "vuelidate/lib/validators";
export default {
  props: {
    fields: { required: true, type: Object | Array },
  },
  components: {
    draggable,
    Date,
    DateInput,
    ModalSelectBranch,
    OtherLoading,
    FieldChoiceDetail,
    FieldTextDetail,

    InputText,
    InputTextArea,
    Multiselect,
  },
  validations() {
    return {
      additionnalQuestion: {
        $each: {
          name: {
            required: requiredIf(function (item) {
              return item.field_type_id == 14 || item.field_type_id == 13
                ? false
                : true;
            }),
          },
        },
      },
    };
  },
  data() {
    return {
      value: [],
      isLoading: true,
      isBusy: false,
      isSubmit: false,
      questionList: [],
      activeIndex: 0,
      questionProfileIndex: null,
      showProfilePanel: true,
      additionnalQuestion: [],
      branchList: [],
      fieldTypes: [
        { id: 1, name: "Field" },
        { id: 2, name: "Checkbox" },
        { id: 3, name: "Multiple Choice" },
        { id: 4, name: "Dropdown" },
        { id: 5, name: "DateTime" },
        { id: 6, name: "Branch dropdown" },
        { id: 7, name: "Date" },
        { id: 8, name: "Image" },
        { id: 10, name: "Title" },
        { id: 11, name: "Subtitle" },
        { id: 12, name: "Text Area" },
        { id: 13, name: "Space" },
      ],

      activeIndex: 0,
    };
  },
  computed: {},

  async created() {
    this.getField();
    this.getFieldType();
  },
  methods: {
    async getFieldType() {
      const { data } = await this.axios(`/CustomObject/fieldtype`);
      if (data.result == 1) {
        this.fieldTypes = data.detail.fieldTypes.filter((type) => type.id != 9);
      }
    },

    async getField() {
      for (const field of this.fields) {
        if (field.field_type_id === 6) {
          this.isLoading = true;
          await this.getBranchList(field, field.branch_group_id);
        }
      }
      this.additionnalQuestion = this.fields;
    },
    handleCheckRequired(val, items) {
      if (val) {
        this.$nextTick(() => (items.is_display = 1));
      }
    },
    disabledField(items) {
      if (
        items.field_profile_type_id == 3 ||
        items.field_profile_type_id == 5 ||
        items.field_profile_type_id == 10
      ) {
        return true;
      }
      if (items.is_profile_required) {
        return true;
      }
      if (this.isRegister) {
        return true;
      }
      return false;
    },

    // questionDetailText() {
    //   const text = this.questionList
    //     .filter((el) => el.is_display == 1)
    //     .map((els) => els.name)
    //     .join(",");
    //   return text;
    // },
    questionTypeText(type) {
      if (type != undefined)
        if (type == 0) {
          return "Customer Detail";
        } else {
          let index = this.fieldTypes.findIndex((field) => field.id == type);
          return this.fieldTypes[index].name;
        }
    },
    addQuestion() {
      this.additionnalQuestion.push({
        id: 0,
        serial_custom_object_id: 0,
        temp_index: this.additionnalQuestion.length + 1,
        field_type_id: 1,
        required: false,
        img: "",
        name: "",
        is_display: 1,
        is_required: 0,
        delete: false,
        validate_text: "Please input text.",
        field_profile_type_id: 0,
        select_branch: [],
        field_choices: [],
        field_conditions: [
          {
            serial_custom_object_field_id: 0,
            field_condition_type_id: 1,
            value: "text",
          },
          {
            serial_custom_object_field_id: 0,
            field_condition_type_id: 2,
            value: "",
          },
          {
            serial_custom_object_field_id: 0,
            field_condition_type_id: 3,
            value: "",
          },
          {
            serial_custom_object_field_id: 0,
            field_condition_type_id: 4,
            value: "",
          },
        ],
      });
      this.activeIndex = this.additionnalQuestion.length - 1;
    },

    typeHidden(type) {
      if (type == 9 || type == 10 || type == 11 || type == 13 || type == 14) {
        return true;
      } else {
        return false;
      }
    },
    handleClickActive(val, index) {
      this.activeIndex = index;
    },
    deleteQuestion() {
      this.additionnalQuestion.splice(this.activeIndex, 1);
      // this.additionnalQuestion[this.activeIndex].delete = true;
      this.activeIndex -= 1;
      if (this.additionnalQuestion.length == 0) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = this.activeIndex;
      }
    },
    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
      this.branchList = this.$store.state.coupon.branchList.detail;
    },
    changeDrag(value) {
      this.activeIndex = value.newIndex;
    },
    async changeQeustionType(value) {
      if (value == 1) {
        this.additionnalQuestion[this.activeIndex].field_conditions = [
          { field_condition_type_id: 1, value: "text" },
          { field_condition_type_id: 2, value: "" },
          { field_condition_type_id: 3, value: "" },
          { field_condition_type_id: 4, value: "" },
        ];
        this.additionnalQuestion[this.activeIndex].field_choices = [];
      } else if (value >= 2 && value <= 4) {
        this.additionnalQuestion[this.activeIndex].field_conditions = [];
        this.additionnalQuestion[this.activeIndex].field_choices = [
          { name: "choice 1", sort_order: 0, isDefault: 0 },
        ];
      } else if (value == 5 || value == 7) {
        this.additionnalQuestion[this.activeIndex].field_conditions = [];
        this.additionnalQuestion[this.activeIndex].field_choices = [
          { name: "" },
        ];
      } else if (value == 6) {
        this.additionnalQuestion[this.activeIndex].field_choices = [];
        this.additionnalQuestion[this.activeIndex].field_conditions = [];
        let idBranch = 0;
        if (this.additionnalQuestion[this.activeIndex].branch_group_id) {
          idBranch = this.additionnalQuestion[this.activeIndex].branch_group_id;
        }
        // this.isLoading = true;
        await this.getBranchList(
          this.additionnalQuestion[this.activeIndex],
          idBranch
        );
      } else {
        this.additionnalQuestion[this.activeIndex].field_conditions = [];
        this.additionnalQuestion[this.activeIndex].field_choices = [];
      }
      if (value != 6) {
        delete this.additionnalQuestion[this.activeIndex].branchList;
      }
      if (this.typeHidden(value)) {
        this.additionnalQuestion[this.activeIndex].required = false;
        this.additionnalQuestion[this.activeIndex].name = "";
      }
      this.additionnalQuestion[this.activeIndex].validate_text =
        this.defaultValidateText(value);
    },
    defaultValidateText(type) {
      if (type >= 2 && type <= 4) {
        return "Please select choice.";
      } else if (type == 5 || type == 7) {
        return "Please enter date.";
      } else if (type == 6) {
        return "Please select branch.";
      } else if (type == 8) {
        return "Please upload image.";
      } else if (type == 10 || type == 11 || type == 13 || type == 14) {
        return "";
      } else {
        return "Please input text.";
      }
    },
    selectBranch(value) {
      this.additionnalQuestion[this.activeIndex].select_branch = value;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-normal {
  color: #5b5b5b;
}
.text-light-grey {
  color: #9a9a9a;
}
::v-deep .text-float-normal .input-custom {
  label {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 100;
  }
}
.header-title {
  height: 100%;
  padding: 8px 16px;
  margin-top: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-btn {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}
.add-btn:hover {
  background-color: var(--primary-color);
}
.question-panel {
  max-height: 50vh;
  background-color: white;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

.question-card {
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.24);

  border-left: 5px solid transparent;
  background-color: white;
  border-radius: 4px;
  text-overflow: ellipsis;
}
.question-card.active {
  border-left: 5px solid var(--primary-color);
}
.type-box {
  padding: 2px 8px;
  color: var(--primary-color);
  background-color: var(--theme-secondary-color);
  border-radius: 6px;
}
.icon-size {
  font-size: 20px;
  color: #575757;
}
.icon-size.disabled {
  color: #bababa;
}
#footer {
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  height: 10%;
  padding: 15px;
  background: #fff;
}
.margin-input {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.margin-input-5 {
  margin-bottom: 5px !important;
}
::v-deep .question-name-input.error {
  input {
    border: 1px solid red;
  }
}
.dropdown-input-field ::v-deep button {
  overflow: hidden;
  color: var(--font-main-color);
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  text-align: left;
  padding: 0.375rem 0.75rem;
  background: white;
}
::v-deep .multiselect__single {
  font-size: 14px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
