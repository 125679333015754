var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"p-3 bg-white mb-5"},[_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('div',{staticClass:"header-secondary title-tabs header-title py-3"},[_c('b',[_vm._v("Question List")]),_c('b-button',{staticClass:"add-btn",on:{"click":_vm.addQuestion}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"plus-square"}}),_c('span',[_vm._v("Create New")])],1)],1)]),_c('b-col',{attrs:{"sm":"8"}},[_c('div',{staticClass:"header-secondary title-tabs header-title d-none d-sm-block py-3"},[_c('b',[_vm._v("Detail")])])])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"4"}},[_c('draggable',{attrs:{"list":_vm.additionnalQuestion},on:{"end":_vm.changeDrag}},_vm._l((_vm.additionnalQuestion),function(addQues,index){return _c('div',{key:'add-' + index,staticClass:"p-2 question-panel shadow-sm overflow-auto",on:{"click":function($event){return _vm.handleClickActive(true, index)}}},[_c('div',{class:[
                'mt-2 p-3 question-card pointer',
                index == _vm.activeIndex && 'active',
              ]},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"flex-flow":"wrap"}},[_c('span',{staticClass:"mr-2 text-black font-weight-bold"},[_vm._v("Question "+_vm._s(index + 1)+" :")]),_c('div',{staticClass:"mr-2 type-box"},[_vm._v(" "+_vm._s(_vm.questionTypeText(addQues.field_type_id))+" ")]),_c('span',{staticStyle:{"word-break":"break-all"}})]),_c('div',{staticClass:"mt-1"},[_c('p',{staticClass:"mb-1 text-light-grey text-truncate"},[_vm._v(" "+_vm._s(addQues.name)+" ")])])])])}),0)],1),_c('b-col',{attrs:{"sm":"8"}},[_c('div',{staticClass:"header-secondary title-tabs d-block d-sm-none"},[_c('span',[_vm._v("Detail")])]),_c('div',{staticClass:"pt-2 bg-white text-normal shadow-sm"},[_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"content-between"},[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('label',{staticClass:"mb-0 mr-2"},[_vm._v("Required")]),_c('b-form-checkbox',{staticClass:"mb-1 mr-2",attrs:{"size":"lg","value":1,"unchecked-value":0,"switch":""},model:{value:(_vm.additionnalQuestion[_vm.activeIndex].is_required),callback:function ($$v) {_vm.$set(_vm.additionnalQuestion[_vm.activeIndex], "is_required", $$v)},expression:"additionnalQuestion[activeIndex].is_required"}}),(_vm.activeIndex != 0)?_c('b-icon',{staticClass:"icon-size pointer",attrs:{"size":"lg","icon":"trash-fill"},on:{"click":_vm.deleteQuestion}}):_vm._e()],1)]),_c('div',{staticClass:"mt-3 w-25"},[_c('b-form-select',{attrs:{"options":_vm.fieldTypes,"value-field":"id","text-field":"name"},on:{"change":_vm.changeQeustionType},model:{value:(_vm.additionnalQuestion[_vm.activeIndex].field_type_id),callback:function ($$v) {_vm.$set(_vm.additionnalQuestion[_vm.activeIndex], "field_type_id", $$v)},expression:"additionnalQuestion[activeIndex].field_type_id"}})],1),_c('div',{staticClass:"mt-3"},[(
                  !(
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 10 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 11 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 13 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 14
                  )
                )?_c('div',[_c('InputText',{staticClass:"text-float-normal w-50",attrs:{"textFloat":"Validate Text","placeholder":_vm.additionnalQuestion[_vm.activeIndex].validate_text,"disabled":_vm.additionnalQuestion[_vm.activeIndex].field_type_id == 10 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 11 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 13 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 14,"type":"text"},model:{value:(_vm.additionnalQuestion[_vm.activeIndex].validate_text),callback:function ($$v) {_vm.$set(_vm.additionnalQuestion[_vm.activeIndex], "validate_text", $$v)},expression:"additionnalQuestion[activeIndex].validate_text"}})],1):_vm._e(),(
                  !(
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 9 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 13 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 14
                  )
                )?_c('div',[_c('InputText',{class:[
                    'text-float-normal question-name-input margin-input-5',
                    _vm.isSubmit && !_vm.additionnalQuestion[_vm.activeIndex].name
                      ? 'error'
                      : '',
                  ],attrs:{"isRequired":"","textFloat":"Question","disabled":_vm.additionnalQuestion[_vm.activeIndex].field_type_id == 9 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 13 ||
                    _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 14,"placeholder":_vm.additionnalQuestion[_vm.activeIndex].name,"type":"text","v":_vm.$v.additionnalQuestion.$each.$iter[_vm.activeIndex].name,"isValidate":_vm.$v.additionnalQuestion.$each.$iter[_vm.activeIndex].name
                      .$error},model:{value:(_vm.additionnalQuestion[_vm.activeIndex].name),callback:function ($$v) {_vm.$set(_vm.additionnalQuestion[_vm.activeIndex], "name", $$v)},expression:"additionnalQuestion[activeIndex].name"}})],1):_vm._e()]),_c('div',{staticClass:"my-3"},[(_vm.additionnalQuestion[_vm.activeIndex].field_type_id == 1)?_c('FieldTextDetail',{attrs:{"questionList":_vm.additionnalQuestion,"questionActive":_vm.activeIndex}}):_vm._e(),(
                  _vm.additionnalQuestion[_vm.activeIndex].field_type_id >= 2 &&
                  _vm.additionnalQuestion[_vm.activeIndex].field_type_id <= 4
                )?_c('FieldChoiceDetail',{attrs:{"questionList":_vm.additionnalQuestion,"questionActive":_vm.activeIndex}}):_vm._e(),(_vm.additionnalQuestion[_vm.activeIndex].field_type_id == 5)?_c('DateInput'):_vm._e(),(
                  _vm.additionnalQuestion[_vm.activeIndex].branchList &&
                  _vm.additionnalQuestion[_vm.activeIndex].branchList.length > 0 &&
                  _vm.additionnalQuestion[_vm.activeIndex].field_type_id == 6
                )?_c('ModalSelectBranch',{attrs:{"list":_vm.additionnalQuestion[_vm.activeIndex].branchList,"field":_vm.additionnalQuestion[_vm.activeIndex],"text":"Select Branch","id":"","BranchSelectList":_vm.additionnalQuestion[_vm.activeIndex].select_branch},on:{"selectBranch":_vm.selectBranch}}):_vm._e(),(_vm.additionnalQuestion[_vm.activeIndex].field_type_id == 7)?_c('Date',{}):_vm._e(),(_vm.additionnalQuestion[_vm.activeIndex].field_type_id == 12)?_c('InputTextArea',{staticClass:"mt-3 custom-input",attrs:{"textFloat":"","type":"text","placeholder":"","rows":"3"}}):_vm._e()],1)])])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }